#navbarLogo {
	max-height: 100px;
	max-width: 100px;
}
.marketing b {
	font-size: xx-large;
}
footer a {
	text-decoration: none;
	color: white;
}
footer a:hover {
	color: black;
}
.attr_list {
	margin-top: 15px;
	margin: 10px;
	padding: 5px;
	display: inline;
	background-color: whitesmoke;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
@media screen and (max-width: 972px) {
	.car_info {
		padding-left: 20vw;
	}
}
.car_info {
	padding-left: 6.5vw;
}
 /* for desktop */
 .whatsapp_float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}

.whatsapp-icon {
	margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
	.whatsapp-icon {
		margin-top: 10px;
	}

	.whatsapp_float {
		width: 40px;
		height: 40px;
		bottom: 20px;
		right: 10px;
		font-size: 22px;
	}
}
